// import React from "react";
// import "./RebrandingBanner.css";
// import PropTypes from "prop-types";

// const RebrandingBanner = ({ className }) => {
//   return (
//     <div className={`rebranding-banner ${className || ""}`}>
//       Exciting news! SpaceAge Labs been acquired by{" "}
//       <a
//         href="https://theturingcompany.com/"
//         target="_blank"
//         rel="noopener noreferrer"
//         className="rebranding-link"
//       >
//         Turing AI Corporation
//       </a>
//       . As part of this transition, hydroEye AI will be rebranded as TOP Clear. Stay tuned for a new look very soon!
//     </div>
//   );
// };


// export default RebrandingBanner;

import * as React from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
// import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Link from '@mui/material/Link';
import "./RebrandingBanner.css";
import PropTypes from "prop-types";

const RebrandingBanner = ({ className }) => {

    const [visible, setVisible] = React.useState(true);

    React.useEffect(() => {
      const bannerVisibility = sessionStorage.getItem("rebrandingBannerVisible");
      if (bannerVisibility === "false") {
        setVisible(false);
      }
    }, []);
  
    const handleClose = () => {
      setVisible(false);
      sessionStorage.setItem("rebrandingBannerVisible", "false");
    };
  
    if (!visible) {
      return null;
    }

    return (
        <Snackbar 
            open={visible} 
            autoHideDuration={60000} 
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            sx={{ width: '85%',  'fontFamily': 'Poppins'}}
        >
            <Alert
            onClose={handleClose}
            severity="info"
            variant="outline"
            sx={{ 
                width: '100%', 
                'fontFamily': 'Poppins', 
                fontSize: '1.1rem',
                background: 'linear-gradient(5deg, rgba(0, 0, 0, 1), rgba(0, 25, 113, 1)) !important', 
                'border-color': '#001971',
                color: 'white !important'
            }}
            >
            Exciting news! SpaceAge Labs has been acquired by{" "}
                 <Link
                     href="https://theturingcompany.com/"
                     target="_blank"
                     color="inherit"
                     rel="noopener noreferrer"
                     className="rebranding-link"
                     sx={{ 
                        color: '#00FFEB !important'
                    }}
                 >
                     <b>Turing AI Corporation</b>
                 </Link>
                 . As part of this transition, <b>hydroEye AI</b> will be rebranded as <b>TOP Clear.</b><br/>Stay tuned for a new look very soon!
            </Alert>
        </Snackbar>
    );
}

RebrandingBanner.propTypes = {
    className: PropTypes.string,
};

RebrandingBanner.defaultProps = {
    className: "",
};


export default RebrandingBanner;
